import React from 'react';

const Logo = ({ width, height }) => {
  return (
    <svg width={width} viewBox="0 0 273 175" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M142 0H190.208L231 175H182.792L142 0Z" fill="#2096D8" />
      <path d="M142 0H190.208L171 124.5L142 0Z" fill="#127BB6" />
      <path d="M57 0H103.042L142 175H95.9583L57 0Z" fill="#2096D8" />
      <path d="M57 0H103.042L75 81L57 0Z" fill="#127BB6" />
      <path d="M103 0H57L0 69.5L45 54.5L52 99.5L103 0Z" fill="#62D9FF" />
      <path d="M273 0H224.25L183 175H231.75L273 0Z" fill="#62D9FF" />
      <path d="M190 0H139.083L96 175H146.917L190 0Z" fill="#62D9FF" />
    </svg>




  );
}

export default Logo;
